import React, { useEffect } from "react";
import ReactGA from 'react-ga';

const AboutBox = ()=>{

    useEffect(()=>{
        ReactGA.pageview('/about', ['tracker1'])
    }, [])

    return (
        <div className="about__boxes grid">

            <div className="about__box">
                <i className="about__icon icon-fire"></i>

                <div>
                    <h3 className="about__tile">37</h3>
                    <span className="about__subtitle">Project completed</span>
                </div>
            </div>

            <div className="about__box">
                <i className="about__icon icon-people"></i>

                <div>
                    <h3 className="about__tile">37</h3>
                    <span className="about__subtitle">Satisfied clients</span>
                </div>
            </div>

            <div className="about__box">
                <i className="about__icon icon-badge"></i>

                <div>
                    <h3 className="about__tile">4</h3>
                    <span className="about__subtitle">Certificates</span>
                </div>
            </div>

        </div>
    )
}

export default AboutBox;
