import React from "react";
import "./about.css";
import AboutImage from "../../assets/avatar-2.svg";
import AboutBox from "./AboutBox.jsx"

const About = ()=>{
    return (
        <section className="about container section" id="about">
            <h2 className="section__title">About Me</h2>

            <div className="about__container grid">
                <img src={AboutImage} alt="" className="about__img" />

                <div className="about__data grid">

                    <div className="about__info">
                        <p className="about__description">
                            I'Am Raka Janitra, a software engineer from Jakarta, Indonesia. I have
                            rich experience in web development (Frontend and Backend) been long time 3 years. Also i'm good
                            at DevOps (Developer Operational) and Linux.
                        </p>
                    </div>

                    <div className="about__skills grid">

                        <div className="skills__data">
                            <div className="skills__titles">
                                <h3 className="skills__name">Development</h3>
                                <span className="skills__number">90%</span>
                            </div>
                            <div className="skills__bar">
                                <span className="skills__percentage development"></span>
                            </div>
                        </div>

                        <div className="skills__data">
                            <div className="skills__titles">
                                <h3 className="skills__name">Analyst System</h3>
                                <span className="skills__number">82%</span>
                            </div>
                            <div className="skills__bar">
                                <span className="skills__percentage as"></span>
                            </div>
                        </div>

                        <div className="skills__data">
                            <div className="skills__titles">
                                <h3 className="skills__name">Algorithms and Structure Data</h3>
                                <span className="skills__number">92%</span>
                            </div>
                            <div className="skills__bar">
                                <span className="skills__percentage algo"></span>
                            </div>
                        </div>

                    </div>

                </div>

            </div>

            <AboutBox />

        </section>
    )
}

export default About;
