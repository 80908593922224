import React from "react";
import "./pricing.css";
import Image1 from "../../assets/price-1.svg";
import Image2 from "../../assets/price-2.svg";
import Image3 from "../../assets/price-3.svg";

const Pricing = ()=>{
    return (
       <section className="pricing container section">
           <h2 className="section__title">Pricing Plans</h2>

           <div className="pricing__container grid">
               <div className="pricing__item">
                   <img src={Image1} alt="" className="pricing__img" />
                   <h3 className="pricing__plan">Basic</h3>
                   <p className="pricing__title">A Simple option for bug fixing on your program (individual)</p>
                   <p className="pricing__support">Support till bugs were done</p>
                   <h3 className="price">
                       <em>$</em> 25 <span>/ Project</span>
                   </h3>
                   <a href="#contact" className="btn">Get started</a>
               </div>

               <div className="pricing__item best">
                   <div className="badge">Recommended</div>
                   <img src={Image2} alt="" className="pricing__img" />
                   <h3 className="pricing__plan">Premium</h3>
                   <p className="pricing__title">Develop program for thesis need, hosting on cloud, and include revision</p>
                   <p className="pricing__support">Support til project was done</p>
                   <h3 className="price">
                       <em>$</em> 300 <span>/ Project</span>
                   </h3>
                   <a href="#contact" className="btn">Get started</a>
               </div>

               <div className="pricing__item">
                   <img src={Image3} alt="" className="pricing__img" />
                   <h3 className="pricing__plan">Ultimate (Upcoming)</h3>
                   <p className="pricing__title">A wise option for large companies and individuals</p>
                   <p className="pricing__support">24/7 support</p>
                   <h3 className="price">
                       <em>$</em> - <span>Month</span>
                   </h3>
                   <a href="#contact" className="btn">Get started</a>
               </div>
           </div>

       </section>
    )
}

export default Pricing
