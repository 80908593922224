import Work1 from "../../assets/work-1.svg"
import Work2 from "../../assets/work-2.svg"
import Work3 from "../../assets/work-3.svg"
import Work4 from "../../assets/work-4.svg"
import Work5 from "../../assets/work-5.svg"
import Work6 from "../../assets/work-6.svg"

const Menu = [
    {
        id: 1,
        image: Work1,
        title: "Assessment Management Illustration",
        category: "Design",
        link: "https://www.figma.com/proto/nZraSpt3X6VHv5KjuXPVa5/SIA-UI?node-id=11%3A0&scaling=scale-down&page-id=0%3A1",
    },
    {
        id: 2,
        image: Work2,
        title: "Tracker Covid",
        category: "Coding",
        link: "https://jrarkaan.github.io/trackerCovid19/"
    },
    {
        id: 3,
        image: Work3,
        title: "Karate KATA Online Virtual Assessment",
        category: "Coding",
        link: "http://patriot.lemkari.org/"
    },
    {
        id: 4,
        image: Work4,
        title: "Talenta Clone Concept Wireframe (Upcoming)",
        category: "Design",
        link: ""
    },
    {
        id: 5,
        image: Work5,
        title: "Talenta Clone Concept Program (Upcoming)",
        category: "Coding",
        link: ""
    },
];

export default  Menu
