import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2'
import {
    PostSendEmail
} from "../../actions/notificationActions.js";
// import css
import "./contact.css";

const Contact = ()=>{
    const [nameSender, setNameSender] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [body, setBody] = useState('');

    const dispatch = useDispatch();
    const resultSendEmail = useSelector(state => state.sendingEmail);
    const { loading, error, data } = resultSendEmail;

    useEffect(()=>{

        if(loading){
            Swal.fire({
                title: 'Sending e-mail...',
                html: 'Please wait...',
                allowEscapeKey: false,
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading()
                }
            });
        }else{
            setNameSender('');
            setEmail('');
            setSubject('');
            setBody('');
            if(error !== undefined && error == null){

                Swal.fire({
                    position: 'center',
                    icon: 'info',
                    title: 'info',
                    text: 'Success send e-mail to Author',
                    showConfirmButton: false,
                    timer: 1500
                })

                dispatch({
                   type: "NOTIFICATION_SEND_EMAIL_RESET"
                });

            }else if(error !== undefined && error != null){

                const {
                    status, message: {
                        response: {
                            status: httpStatus, data: {
                                error_key, message: httpMessage
                            }
                        }
                    }
                } = error;

                if(Number(httpStatus) === 401){

                    console.info(`refresh page`)

                    Swal.fire({
                        position: 'center',
                        icon: 'info',
                        title: 'info',
                        text: `Website needed refresh. Please waited the process`,
                        showConfirmButton: false,
                        timer: 2000
                    })

                    dispatch({
                        type: "NOTIFICATION_SEND_EMAIL_RESET"
                    });

                    // remove token from localStorage
                    localStorage.removeItem('token');
                    window.location.reload();

                }else{

                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'failed',
                        text: `${httpMessage}`,
                        showConfirmButton: false,
                        timer: 2000
                    })

                    dispatch({
                        type: "NOTIFICATION_SEND_EMAIL_RESET"
                    });
                }

            }

        }


    }, [loading, error, dispatch])

    const validateEmail = (email) => {
        const re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        return re.test(email);
    }

    const validateInputUser = ()=>{
        const resultValidateEmail = validateEmail(email);
        let resultValidate = false
        if(nameSender.length <= 3){
            Swal.fire({
                position: 'top-end',
                icon: 'warning',
                title: 'info',
                text: 'Ups? please input name not under 3 words',
                showConfirmButton: false,
                timer: 1500
            })
            resultValidate = true;
        }else if(email.length <= 7 || !resultValidateEmail){
            Swal.fire({
                position: 'top-end',
                icon: 'warning',
                title: 'info',
                text: 'Ups? please input e-mail with correctly',
                showConfirmButton: false,
                timer: 1500
            })
            resultValidate = true;
        }else if (subject.length <= 3){
            Swal.fire({
                position: 'top-end',
                icon: 'warning',
                title: 'info',
                text: 'Ups? please input subject not under 3 words',
                showConfirmButton: false,
                timer: 1500
            })
            resultValidate = true;
        }else if (body.length <= 3){
            Swal.fire({
                position: 'top-end',
                icon: 'warning',
                title: 'info',
                text: 'Ups? please input body not under 3 words',
                showConfirmButton: false,
                timer: 1500
            })
            resultValidate = true;
        }
        return resultValidate
    }

    const submitHandler = (e)=> {
        e.preventDefault();

        const resultValdiate = validateInputUser()
        if(!resultValdiate){
            // send to email
            dispatch(PostSendEmail(nameSender, body, subject, email));
        }
    };

    return (
        <section className="contact container section" id="contact">
            <h2 className="section__title">Get In Touch</h2>

            <div className="contact__container grid">
                <div className="contact__info">
                    <h3 className="contact__title">Let's talk about everything!</h3>
                    <p className="contact__details">Need more information about a project? send me an email. 👋</p>
                </div>

                <form action="" className="contact__form" onSubmit={submitHandler}>
                    <div className="contact__form-group">

                        <div className="contact_form-div">
                            <input
                                type="text"
                                className="contact__form-input"
                                placeholder="Insert your name"
                                onChange={(e)=> setNameSender(e.target.value)}
                                value={nameSender}
                            />
                        </div>

                        <div className="contact_form-div">
                            <input
                                type="email"
                                className="contact__form-input"
                                placeholder="Insert your e-mail"
                                onChange={(e)=> setEmail(e.target.value)}
                                value={email}
                            />
                        </div>
                    </div>
                    <div className="contact_form-div">
                        <input
                            type="text"
                            className="contact__form-input"
                            placeholder="Insert your subject"
                            onChange={(e)=> setSubject(e.target.value)}
                            value={subject}
                        />
                    </div>

                    <div className="contact_form-div contact__form-area">
                            <textarea
                                name=""
                                id=""
                                cols="30"
                                rows="10"
                                className="contact__form-input"
                                placeholder="write your message"
                                onChange={(e)=> setBody(e.target.value)}
                                value={body}
                            ></textarea>
                    </div>
                    <button className="btn">Send Message</button>
                </form>
            </div>
        </section>
    )
}

export default Contact
