import React from 'react';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import ReactGA from 'react-ga';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import store from "./store.js";
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: "GTM-WK3JF76"
}
TagManager.initialize(tagManagerArgs)

const TRACKING_ID = "UA-194261412-3"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

Sentry.init({
    dsn: "https://0be9c0449e93454d8b35026ce65e4f25@o529778.ingest.sentry.io/4504353497088000",
    integrations: [new BrowserTracing({ tracingOrigins: ["*"] })],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <App />
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
