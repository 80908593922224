import * as Sentry from "@sentry/react";
import {
    apiNotification
} from '../config/config.js';

export const PostSendEmail = (nameSender, body, subject, emailSender) => async(dispatch, getState)=>{
    try{

        const userInformation = `{ email: ${emailSender} } - { name sender: ${nameSender} } - ${body}`;

        const bodyJson = {
            receive_email: ["rakajanitraa@hotmail.com"],
            subject,
            body: userInformation
        }

        dispatch({
            type: 'NOTIFICATION_SEND_EMAIL',
            loading: true,
        })

        const {
            authGetToken
        } = getState();

        let newToken
        if(typeof authGetToken.data === "undefined"){
            newToken = authGetToken
        }else{
            newToken = authGetToken.data.token
        }

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${newToken}`
            }
        };

        const {
            data: {
                status, data, status_code, message
            }
        } = await apiNotification.post(`/notification/email`, bodyJson, config);

        if(Number(status) === 0){

            dispatch({
                type: "NOTIFICATION_SEND_EMAIL_FAILS",
                payload: {
                    status: -1,
                    statusCode: status_code,
                    message,
                },
                loading: false,
            });
        }else if(Number(status) === 1){

            dispatch({
                type: "NOTIFICATION_SEND_EMAIL_SUCCESS",
                payload: {
                    status: 1,
                    statusCode: status_code,
                    message,
                },
            });

        }

    }catch(error){
        Sentry.captureException(error);
        console.error(`~~~~~~ ERROR ON CATCH ACTION SEND EMAIL ${error} ~~~~~~~~`);
        dispatch({
            type: "NOTIFICATION_SEND_EMAIL_FAILS",
            payload: {
                status: 0,
                statusCode: error.status,
                message: error,
            },
        });
    }
}
