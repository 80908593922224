import * as Sentry from "@sentry/react";
import {
    apiNotification
} from '../config/config.js';

export const GetToken = () => async function(dispatch){
    try{
        localStorage.removeItem('token');

        dispatch({
            type: "AUTH_GET_TOKEN",
        });

        const {
            data: {
                status, data
            }, status: statusHttp, status_code, message
        } = await apiNotification.get('/auth/generate-token');

        if(Number(status) === 0){

            dispatch({
                type: "AUTH_GET_TOKEN_FAILS",
                payload: {
                    status: -1,
                    statusCode: status_code,
                    message,
                    token: null,
                },
            });
        }else if(Number(status) === 1){

            dispatch({
                type: "AUTH_GET_TOKEN_SUCCESS",
                payload: {
                    status: 1,
                    statusCode: status_code,
                    message,
                    token: data.token,
                },
            });

            localStorage.setItem('token', JSON.stringify(data.token));
        }

    }catch(error){
        Sentry.captureException(error);
        console.error(`~~~~~~ ERROR ON CATCH ACTION GET TOKEN ${error} ~~~~~~~~`);
        dispatch({
            type: "AUTH_GET_TOKEN_FAILS",
            payload: {
                status: 0,
                statusCode: error.status,
                message: error,
                token: null,
            },
        });
    }
}
