import React, { useEffect } from "react";
import ReactGA from 'react-ga';
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import "./testimonials.css";
import Image1 from "../../assets/woman-1.png"
import Image2 from "../../assets/avatar-1.svg"
import Image3 from "../../assets/avatar-3.svg"
import Image4 from "../../assets/woman-2.png"
import Image5 from "../../assets/woman-3.png"

const data = [
    {
        id: 1,
        image: Image4,
        title: "Witri Nurul F",
        subtitle: "Product Manager/ Owner Specialist Market B2C at Kelas Pintar",
        comment:
           "Selama bekerja dengan Raka sebagai product managernya yang direct ke dia, Raka sangat helpful. Nggak jarang Raka juga suka offering solution dari segi technical, baiknya gimana buruknya gimana. " +
            "Raka juga punya tanggung jawab dan ownership yang cukup tinggi terkait pekerjaannya atau project yang sedang dia ampuh, nggak jarang dia harus begadang ataupun kerja di weekend demi memenuhi deadline yang udah diset di timeline pada saat kerja langsung. " +
            "Kemampuan Raka menyelesaikan pekerjaan dalam waktu yang cukup cepat sudah sangat bagus dan direkomendasikan. "
    },
    {
        id: 2,
        image: Image1,
        title: "Ike Rizky Amelia",
        subtitle: "United creative",
        comment:
            "Bekerja bareng Raka asik dan penuh tantangan. Termasuk orang yang cekatan, proyek apapun kalo dihandle Raka pasti selesai dengan hampir sempurna. " +
            "Dengan ambisinya yang tinggi, yang ngebuat semangat orang sekitarnya juga naik. Selalu nge-share energy positif pokoknya 👍",
    },
    {
        id: 3,
        image: Image3,
        title: "Ricky Abdullah",
        subtitle: "Product designer at PT Pelindo (BUMN)",
        comment:
            "Gua terbantu sih sama si raka, karena dia yang bantu gua ngerjain program buat Tugas Akhir, setiap ada error pasti dia bantu klo emang dia bisa, pokoknya terbaik lah si raka ini. " +
            "Gua sih nyebut dia robot soalnya hampir bisa semua bahasa pemrograman, wkwkwkwk 😂👍",
    },
    {
        id: 4,
        image: Image2,
        title: "Imam",
        subtitle: "QA Engineer at Bank Mandiri",
        comment:
            "Sangat membantu dan termasuk expert sekali dalam menggunakan Laravel 7 (PHP), Codeigniter 3 (PHP), jQuery, Ajax, dan dll. yang engga ada ngasih taunya error nya dimana, wkwkkw." +
            " Makasih banyak dan semoga sukses terus serta sehat selalu yaa brader 😊😊.",
    },
    {
        id: 5,
        image: Image5,
        title: "Veronica",
        subtitle: "Full-Stack Developer at IGP Group",
        comment:
            "Raka support project CRUD gue, lumayan sangat bantu untuk saat itu. Walaupun raka tidak banyak turun tangan ke dalam project gue, namun sedikit terbantu untuk Bug Fixing. "
    },
    {
        id: 6,
        image: Image1,
        title: "Vhairin",
        subtitle: "QA Manual Tester",
        comment:
          "Raka sangat baik, keren, helping me when i am stuck. Kekurangannya terlalu sibuk jadi belum bisa ASAP, sehingga harus dari jauh-jauh hari untuk mintolnya ygy." +
            " kalo kepepet kasian rakanya ga tidur ntar. 🥲"
    },
    {
        id: 7,
        image: Image2,
        title: "Fhitras A",
        subtitle: "Entrepreneur",
        comment:
           "Sangat interaktif, Raka mudah menerjemahkan codingan apa yg disampaikan dalam bentuk kebutuhan bisnis, enjoy/ santai, sangat bertanggung jawab, dan ambil andil dalam program. Mantap 🔥"
    },
    {
        id: 8,
        image: Image4,
        title: "Mayang Pusfitasari",
        subtitle: "IT Implement at Bosnet",
        comment:
            "Raka sangat totalitas dalam membantu menyelesaikan project Sistem Pendukung Keputusan dengan metode yang cukup kompleks dan dapat menyelesaikannya sesuai target waktu yang ditentukan, yang menunjukkan bahwa Raka memiliki time management yang baik. " +
            "Raka bisa memberi insight dengan berbagai pilihan solusi dan konsekuensinya agar kita memiliki dasar dalam mengambil keputusan seperti apa sistem akan dibuat."
    }
];

const Testimonials = ()=>{

    useEffect(()=>{
        ReactGA.pageview('/reviews', ['tracker1'])
    }, [])

    return (
        <section className="testimonials container section" id="comment">
            <h2 className="section__title">Clients and Reviews</h2>

            <Swiper
                className="testimonials__container grid"
                modules={[Pagination]}
                spaceBetween={50}
                slidesPerView={1}
                loop={true}
                grabCursor={true}
                pagination={{ clickable: true }}
            >
                {
                    data.map(({id, image, title, subtitle, comment})=>{
                        return (
                            <SwiperSlide className="testimonials__item" key={id}>
                                <div className="thumb">
                                    <img src={image} alt="" />
                                </div>
                                <h3 className="testimonials__title">{title}</h3>
                                <span className="subtitle">{subtitle}</span>
                                <div className="comment">{comment}</div>
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
        </section>
    )
}

export default Testimonials
