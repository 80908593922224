export const PostEmailReducer = (state = {}, action)=> {
    switch(action.type){
        case "NOTIFICATION_SEND_EMAIL":
            return { loading: true };
        case "NOTIFICATION_SEND_EMAIL_FAILS":
            return { loading: false, error: action.payload, data: null };
        case "NOTIFICATION_SEND_EMAIL_SUCCESS":
            return { loading: false, error: null,  data: action.payload };
        case "NOTIFICATION_SEND_EMAIL_RESET":
            return { sendingEmail: null };
        default:
            return state;
    }
};
