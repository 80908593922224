import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import './App.css';
import Sidebar from "./components/sidebar/Sidebar.jsx";
import Home from "./components/home/Home.jsx";
import About from "./components/about/About.jsx";
import Services from "./components/services/Services.jsx";
import Resume from "./components/resume/Resume.jsx";
import Portfolio from "./components/portfolio/Portfolio.jsx";
import Pricing from "./components/pricing/Pricing.jsx";
import Testimonials from "./components/testimonials/Testimonials.jsx";
import ReactGA from 'react-ga';
import Blog from "./components/blog/Blog.jsx";
import Contact from "./components/contact/Contact.jsx";
import {
    GetToken
} from "./actions/authActions.js"

function App() {
    const dispatch = useDispatch();
    const authGetToken = useSelector(state => state.authGetToken);

    useEffect(()=>{

        if(authGetToken === null){
            dispatch(GetToken())
        }

        ReactGA.pageview('/', ['tracker1'])
    }, [authGetToken])

    return (
        <>
            <Helmet>
                <meta name="description" content="Find all the best quality software with your need" />
                <meta name="keywords" content="software engineer, software development, cheap price software, raka, raka janitra, arkaan, raka blek, jasa joki, joki program"/>
            </Helmet>
            <Sidebar />
            <main className="main">
              <Home />
              <About />
              <Services />
              <Resume />
              <Portfolio />
              <Pricing />
              <Testimonials />
              {/*<Blog />*/}
              <Contact />
            </main>
        </>
    )
}

export default App;
