export const AuthGetTokenReducer = (state = {}, action)=> {
    switch(action.type){
        case "AUTH_GET_TOKEN":
            return { loading: true };
        case "AUTH_GET_TOKEN_FAILS":
            return { loading: false, error: action.payload, data: null };
        case "AUTH_GET_TOKEN_SUCCESS":
            return { loading: false, error: null,  data: action.payload };
        default:
            return state;
    }
};
