import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
// import auth
import {
    AuthGetTokenReducer
} from "./reducers/authReducers.js";
// import sending notification services
import {
    PostEmailReducer
} from "./reducers/notificationReducers.js"

const reducer = combineReducers({
    authGetToken: AuthGetTokenReducer,
    sendingEmail: PostEmailReducer,
});

const tokenInfoFromStorage = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : null;

// initial State
const initialState = {
    authGetToken: tokenInfoFromStorage
}

const middleware = [thunk];

const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)));

export default store;
