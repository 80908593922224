import React, { useEffect } from "react";
import ReactGA from 'react-ga';
import "./home.css";
import Me from "../../assets/avatar-1.svg";
import image from "../../assets/1000023681.jpg"
import HeaderSocials from "./HeaderSocials";
import ScrollDown from "./ScrollDown.jsx";
import Shapes from "./Shapes.jsx";

const Home = ()=>{

    useEffect(()=>{
        ReactGA.pageview('/home', ['tracker1'])
    }, [])

    return (
        <section className="home container" id="home">
            <div className="intro">
                <img src={image} alt="" className="home__img" />
                <h1 className="home__name">Raka Janitra Arkaan</h1>
                <span className="home__education">I'am a Software Engineer</span>

                <HeaderSocials />

                <div className="contact__button">
                    <a href="#contact" className="btn">Contact me</a>
                </div>

                <ScrollDown />
            </div>

            {/*<Shapes />*/}
        </section>
    )
}

export default Home;
