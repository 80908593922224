import React, { useEffect } from "react";
import ReactGA from 'react-ga';
import "./services.css";
import Image1 from "../../assets/1368-removebg-preview.png";
import Image2 from "../../assets/world.png";
import Image3 from "../../assets/4648-removebg-preview.png";

const data = [
    {
        id: 1,
        image: Image1,
        className: "services__img s_img__1",
        title: "Delivery What Do You Want",
        description:
            "As slogan i could be delivered the project have requirement from client. Translate business plan to program needs",
    },
    {
        id: 2,
        image: Image2,
        className: "services__img s_img__2",
        title: "Web Development",
        description:
            "Result translate from business needs is website. Website compatible and solve from problem faced by clients ",
    },
    {
        id: 3,
        image: Image3,
        className: "services__img s_img__3",
        title: "Technology Compatibility",
        description:
            "Don't be worry for the stack use on phase development and after development. i choice stack have good environment for the next " +
            "maintenance",
    },
];

const Services = ()=>{

    useEffect(()=>{
        ReactGA.pageview('/services', ['tracker1'])
    }, [])

    return (
        <section className="services container section" id="services">
            <h2 className="section__title">Services</h2>

            <div className="services__container grid">
                {
                    data.map(({ id, image, title, className, description}) => {
                        return (
                            <div className="services__card" key={id}>
                                <img src={image} alt="" className={className} />
                                <h3 className="services__title">{title}</h3>
                                <p className="services__description">{description}</p>
                            </div>
                        )
                    })
                }
            </div>
        </section>
    )
}

export default Services;
