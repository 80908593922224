import React, { useEffect } from "react";
import ReactGA from 'react-ga';
import "./resume.css";
import Card from "./Card.jsx"

const Data = [
    {
        id: 1,
        category: "education",
        icon: "icon-graduation",
        year: "2017-2021",
        title: "Bachelor's Degree",
        desc: "Polytechnic STMI Jakarta - Information System (Cum Laude). Made final project/ thesis D4 program sarjana terapan with entitled, " +
            "Penerapan RESTFul API dan Arsitektur Microservices pada Web Client PT Brilyan Trimatra Utama Menggunakan Metode Extreme Programming dengan Framework MERN Stack",
    },
    {
        id: 2,
        category: "education",
        icon: "icon-graduation",
        year: "2014 - 2017",
        title: "Academic Degree - Senior High School",
        desc: "SMAN 9 Bekasi - Science.",
    },
    {
        id: 3,
        category: "education",
        icon: "icon-graduation",
        year: "2011 - 2014",
        title: "Academic Degree - Junior High School",
        desc: "SMPN 36 Bekasi.",
    },
    {
        id: 4,
        category: "experience",
        icon: "icon-briefcase",
        year: "September (2022) - Present",
        title: "Software Engineer (Backend) - Pharos Indonesia",
        desc: "Working on Environment Development Stack use: Golang, Node.Js, Docker, Elasticsearch, React.Js. " +
            "Project: Century Express (Core), Etikal.",
    },
    {
        id: 5,
        category: "experience",
        icon: "icon-briefcase",
        year: "January - September 2022",
        title: "Software Engineer - Kelas Pintar",
        desc: "Maintenance and develop some of microservices which are use language programming Node.Js (Express.Js), PHP (Zend Framework) with Databases; MySQL, MongoDB, and Redis. " +
            "Doesn't rule out of the possibility for maintence frontend framework; Angular.Js, React.Js, Vue.Js (Quasar Framework). well, learn pararel with the team DevOps how implement " +
            "the services on environment Docker for production. ",
    },
    {
        id: 6,
        category: "experience",
        icon: "icon-briefcase",
        year: "May (2021) - November (2021)",
        title: "Full-stack Developer - IGP Group (Internship)",
        desc: "Creating future a Web Apps with Framework Laravel (PHP) and active contribution on teamwork.",
    },
];

const Resume = ()=>{

    useEffect(()=>{
        ReactGA.pageview('/resume', ['tracker1'])
    }, [])

    return (
        <section className="resume container section" id="resume">
            <h2 className="section__title">Experience</h2>

            <div className="resume__container grid">
                <div className="timeline grid">
                    {
                        Data.map((val, id)=>{
                            if (val.category === "education"){
                                return (
                                    <Card
                                        key={id}
                                        icon={val.icon}
                                        title={val.title}
                                        year={val.year}
                                        desc={val.desc}
                                    />
                                )
                            }
                        })
                    }
                </div>
                <div className="timeline grid">
                    {
                        Data.map((val, id)=>{
                            if (val.category === "experience"){
                                return (
                                    <Card
                                        key={id}
                                        icon={val.icon}
                                        title={val.title}
                                        year={val.year}
                                        desc={val.desc}
                                    />
                                )
                            }
                        })
                    }
                </div>
            </div>
        </section>
    )
}

export default Resume;
