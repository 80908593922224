import axios from "axios"
const appMode = process.env.REACT_APP_MODE
const baseUrlApiNotification = `${process.env.REACT_APP_API_NOTIFICATION_URL}/${process.env.REACT_APP_API_NOTIFICATION_NAME}/${process.env.REACT_APP_API_NOTIFICATION_PREFIX}/${process.env.REACT_APP_API_NOTIFICATION_VERSION_API}`
let apiNotification;

if(String(appMode).toLowerCase() === "dev"){
    apiNotification = axios.create({
        baseURL: baseUrlApiNotification,
        timeout: 10000,
        headers: {}
    })
}else if(String(appMode).toLowerCase() === "production"){
    apiNotification = axios.create({
        baseURL: baseUrlApiNotification,
        timeout: 10000,
        headers: {}
    })
}


export {
    apiNotification
}
